@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  font-family: "Roboto", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  transition: all .2s;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #7BA4DC;
  transition: all .2s;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #2664C4;
  transition: all .2s;
}

#root {
  height: 100dvh;
  overflow: hidden;
}
